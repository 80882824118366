import Vue from 'vue'
import App from './App.vue'
import './filters'
import router from "./router"
import '../public/js/flexble.js'
import store from './store';

import './assets/css/reset.css';
import 'vant/lib/index.less';
import './styles/global.scss';


// import eventLib from '@/utils/documentEvent.js';
// window.$_EventLib = eventLib;
// 滑动验证
import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify);
//音视频播放
import VideoPlayer from 'vue-video-player';
import 'video.js/dist/video-js.css';
import "vue-video-player/src/custom-theme.css";

Vue.use(VideoPlayer)

import AlleyUI from "./lib"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


import Vant from 'vant';
import 'vant/lib/index.css';
import 'vant/lib/index.less';
Vue.use(Vant);
Vue.use(AlleyUI);



import VueCropper from "vue-cropper";
Vue.use(VueCropper);

import "swiper/css/swiper.min.css"
import { swiper, swiperSlide } from 'vue-awesome-swiper';
Vue.component('Swiper', swiper);
Vue.component('SwiperSlide', swiperSlide);



import globalMixin from '../src/mixins/lineHeightMixin.js';
Vue.mixin(globalMixin); // 引入全局混入

import global from '@/mixins/index.js'
Vue.mixin(global)
import wx from 'weixin-js-sdk';

Vue.prototype.$wx = wx;


// 在这里添加全局变量
Vue.prototype.$globalCloseImage = require('@/assets/image/closeIcon.png');



// Vue.directive('line-height', lineHeightDirective);



// Swiper 代表最外侧容器
// SwiperSlide 代表每一张轮播图
// import Vconsole from 'vconsole'; //移动端调试工具
// if (process.env.NODE_ENV == 'development') {
//   // 打包后隐藏
//   const vConsole = new Vconsole();
//   Vue.use(vConsole);
// }


Vue.prototype.bus=new Vue()






Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
